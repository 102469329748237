<template>
  <div class="main-content">
    <div id="stepper" :class="steps.current === 1 ? 'step1_stepper step1_space' : ''" ref="newRequest-wrapper">
      <div id="step-1-indicator" class="step">
        <div class="title" style="left: -43px">
          <p
            class="step-font"
            :class="
              steps.completed.includes(1)
                ? 'dark-blue-text'
                : 'grey-text'
            "
          >
            スタイル選択
          </p>
        </div>
        <div
          :class="
            steps.completed.includes(1)
              ? 'indicator dark-blue white-text'
              : 'indicator white'
          "
        >
          <span
            style="margin: 0 8px; display: flex"
            v-if="!steps.completed.includes(1)"
          >
            1
          </span>
          <font-awesome-icon
            :icon="{ prefix: 'fas', iconName: 'check' }"
            style="font-size: 16px; margin: 4px 5.5px 0 5.5px; color:#ffffff;"
            v-else
          />
        </div>
      </div>
      <div
        id="step-1-divider"
        :class="
          steps.completed.includes(1)
            ? 'divider dark-blue'
            : 'divider lighter-grey'
        "
      ></div>

      <div id="step-2-indicator" class="step">
        <div class="title" style="left: -43px">
          <p
            class="step-font"
            :class="
              steps.completed.includes(2)
                ? 'dark-blue-text'
                : 'grey-text'
            "
          >
            画像をアップロード
          </p>
        </div>
        <div
          :class="
            steps.completed.includes(2)
              ? 'indicator dark-blue white-text'
              : 'indicator white'
          "
        >
          <span
            style="margin: 0 8px; display: flex"
            v-if="!steps.completed.includes(2)"
          >
            2
          </span>
          <font-awesome-icon
            :icon="{ prefix: 'fas', iconName: 'check' }"
            style="font-size: 16px; margin: 4px 5.5px 0 5.5px; color:#ffffff;"
            v-else
          />
        </div>
      </div>
      <div
        id="step-2-divider"
        :class="
          steps.completed.includes(2)
            ? 'divider dark-blue'
            : 'divider lighter-grey'
        "
      ></div>

      <div id="step-3-indicator" class="step">
        <div class="title" style="left: -14px">
          <p
            class="step-font"
            :class="
              steps.completed.includes(3)
                ? 'dark-blue-text'
                : 'grey-text'
            "
          >
            詳細編集
          </p>
        </div>
        <div
          :class="
            steps.completed.includes(3)
              ? 'indicator dark-blue white-text'
              : 'indicator white'
          "
        >
          <span
            style="margin: 0 8px; display: flex"
            v-if="!steps.completed.includes(3)"
          >
            3
          </span>
          <font-awesome-icon
            :icon="{ prefix: 'fas', iconName: 'check' }"
            style="font-size: 16px; margin: 4px 5.5px 0 5.5px; color:#ffffff;"
            v-else
          />
        </div>
      </div>
      <div
        id="step-3-divider"
        :class="
          steps.completed.includes(3)
            ? 'divider dark-blue'
            : 'divider lighter-grey'
        "
      ></div>

      <div id="step-4-indicator" class="step">
        <div class="title">
          <p
            class="step-font"
            :class="
              steps.completed.includes(4)
                ? 'dark-blue-text'
                : 'grey-text'
            "
          >
            決済
          </p>
        </div>
        <div
          :class="
            steps.completed.includes(4)
              ? 'indicator dark-blue white-text'
              : 'indicator white'
          "
        >
          <span
            style="margin: 0 8px; display: flex"
            v-if="!steps.completed.includes(4)"
          >
            4
          </span>
          <font-awesome-icon
            :icon="{ prefix: 'fas', iconName: 'check' }"
            style="font-size: 16px; margin: 4px 5.5px 0 5.5px; color:#ffffff;"
            v-else
          />
        </div>
      </div>
    </div>
    <div class="mt-5">
      <Step-1 ref="step1" v-if="steps.current === 1"></Step-1>

      <Step-2 ref="step2" v-if="steps.current === 2"></Step-2>

      <Step-3 ref="step3" v-if="steps.current === 3"></Step-3>

      <Step-4 ref="step4" v-if="steps.current === 4"></Step-4>
    </div>
    <div class="step-actions container" :class="steps.current === 1 ? 'step1_space' : ''">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <button
            class="btn btn-ds mt-3 white dark-blue-outline dark-blue-text"
            v-waves.light
            v-on:click="stepAction('previous')"
            v-if="steps.current > 1"
          >
            <font-awesome-icon
              :icon="{ prefix: 'fas', iconName: 'chevron-left' }"
            />
            <span class="ml-3">前へ</span>
          </button>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6" :class="steps.current === 1 ? 'step1-btn' : ''">
          <button
            class="btn btn-ds mt-3 dark-blue white-text"
            v-waves.light
            :disabled="steps.proceedDisabled"
            v-on:click="stepAction('next')"
          >
            <span :class="steps.current === 4 ? '' : 'mr-3' ">
              {{ steps.current === 4 ? '決済に進む' : '次へ' }}
            </span>
            <font-awesome-icon
              v-if="steps.current !== 4"
              :icon="{ prefix: 'fas', iconName: 'chevron-right' }"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';
// Import Services
import {
  cus_stagingService_GetSRData,
  cus_stagingService_GetAllSRItems,
  cus_stagingService_CreateSR,
  cus_stagingService_UpdateSRStyleID,
  cus_stagingService_successInvoicePayment,
} from '../../services/customer/staging';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | New 360°Request',
    };
  },

  components: {
    'Step-1': () => import('./Staging-New-360-Request-Step1.vue'),
    'Step-2': () => import('./Staging-New-360-Request-Step2.vue'),
    'Step-3': () => import('./Staging-New-360-Request-Step3.vue'),
    'Step-4': () => import('./Staging-New-360-Request-Step4.vue'),
  },

  computed: {
    ...mapGetters(['currentUser', 'staging']),
  },

  data() {
    return {
      accessToken: localStorage.getItem('_accessToken'),
      requestID: localStorage.getItem('_staging360RequestID'),
      steps: {
        completed: [],
        current: 1,
        proceedDisabled: false,
      },
      is_invoice_payment: null,
      responseId: null
    };
  },

  created() {
    this.setExistingStagingData();
  },

  mounted() {
  
  },

  methods: {
    setExistingStagingData() {
      let _this = this;

      _this.steps.proceedDisabled = true;

      if (_this.requestID !== null) {
        cus_stagingService_GetSRData({
          id: _this.requestID,
          token: _this.accessToken,
        })
          .then((response) => {
            let stagingInfo = response.data.result;

            this.responseId = stagingInfo.id;

            cus_stagingService_GetAllSRItems({
              id: stagingInfo.id,
              paginated: 0,
              page_size: 0,
              page: 0,
              token: _this.accessToken,
            })
              .then((_response) => {
                let stagingItems = _response.data.result;

                _this.$store.dispatch('setStagingData', {
                  accessToken: _this.accessToken,
                  info: stagingInfo,
                  items: stagingItems,
                  loader: false,
                  uploadAlertModal: true,
                });

                _this.steps.proceedDisabled = false;

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                }, 500);
              })
              .catch((_error) => {
                console.log(_error);

                localStorage.removeItem('_staging360RequestID');

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                  _this.scrollTop();
                }, 500);
              });
          })
          .catch((error) => {
            console.log(error);

            localStorage.removeItem('_staging360RequestID');

            setTimeout(() => {
              _this.$bvModal.hide('modal-loader');
            }, 500);
          });
      } else {
        _this.createInitialSR();
      }
    },

    createInitialSR() {
      let _this = this;

      _this.$store.state.modalLoaderMessage = 'リクエストを作成中';
      _this.$bvModal.show('modal-loader');

      cus_stagingService_CreateSR({
        furnishing_style_id: 1,
        request_category: 2,
        token: _this.accessToken,
      })
        .then((response) => {
          let data = response.data.result;

          _this.requestID = data.id;

          localStorage.setItem('_staging360RequestID', data.id);

          _this.setExistingStagingData();
        })
        .catch((error) => {
          console.log(error);

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },

    async stepAction(action) {
      if (action === 'next') {

        switch (this.steps.current) {
          case 1:
            this.$refs.step1.updateFurnishingStyle();
            break;
          case 2:
            this.steps.completed.push(2);
            this.steps.current = 3;
            break;
          case 3:
            await this.$refs.step3.updateItemData();
            this.steps.completed.push(3);
            this.steps.current = 4;
            break;
          case 4:
            console.log(this.currentUser.user_profile.is_invoice_payment)
            if(this.currentUser.user_profile.is_invoice_payment == '0') {
              this.$refs.step4.showPaymentModal();
              break; 
            }else {
              this.$refs.step4.showInvoiceModal(this.responseId);
              break;
            } 
          default:
            break;
        }
      }


      if (action === 'previous') {
        let index = this.steps.completed.indexOf(this.steps.current - 1);
        if (index > -1) {
          this.steps.completed.splice(index, 1);
        }

        switch (this.steps.current) {
          case 1:
            break;
          case 2:
            this.steps.current = 1;
            break;
          case 3:
            this.steps.current = 2;
            break;
          case 4:
            this.steps.current = 3;
            break;
          default:
            break;
        }
      }

      this.scrollTop();
    },
    scrollTop() {
      //scroll view on top
      var newRequestWrapper = this.$refs['newRequest-wrapper'];
      if (newRequestWrapper) {
        newRequestWrapper.scrollIntoView({
          block: 'center',
          inline: 'nearest',
        });
      }
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#stepper {
  display: flex;
  margin: 26px 0 0 30px;
}

.step-font {
  font-size:0.9rem;
}

@media only screen and (max-width: 767px) {
  #stepper {
    margin: 26px 0 0 0;
  }
}

#stepper .step {
  position: relative;
}

#stepper .step .title {
  position: absolute;
  max-width: 150px;
  top: -26px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  #stepper .step .title {
    display: none;
  }
}

#stepper .step .title p {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#stepper .step .indicator {
  color :#055ab8;
  border-radius: 100px;
  border: 2px solid #055ab8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

#stepper .divider {
  height: 2px;
  width: 200px;
  margin-top: 14px;
  transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.step-actions {
  margin-top: 100px;
  margin-bottom: 50px;
  width: 500px;
  float: right;
}

@media only screen and (max-width: 1199px) {
  .step-actions {
    margin-top: 20px;
    width: 100%;
    float: none;
  }
}

.step1_main_content {
  padding: 110px 0 150px 300px;
  min-height: calc(100vh - 48px);
}

.step1_stepper {
  margin-left: 76px !important;
}

.step1_space {
  margin-right: 60px !important;
}

@media print {
  .step1_main_content {
    padding: 0px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .step1-btn {
    padding-right: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .step1_main_content {
    padding: 120px 0 80px 0;
  }

  .step1_stepper {
    margin-left: 61px !important;
  }

  .step1_space {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 767px) {
  .step1_main_content {
    padding: 120px 0 80px 0;
  }

  .step1_stepper {
    margin-left: 36px !important;
  }

  .step1_space {
    margin-right: 26px !important;
  }
}
</style>
